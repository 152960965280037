import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject, from, merge, of, ReplaySubject, switchMap} from 'rxjs';
import {environment} from '../../environments/environment';
import {SessionExpiredDialogComponent} from '../comps/session-expired-dialog/session-expired-dialog.component';
import {OrganisationService} from '../services/organisation.service';
import {UserService} from '../services/user.service';
import {
  fetchAuthSession,
  getCurrentUser,
  signIn, type SignInInput,
  signUp,
  signOut,
  resendSignUpCode,
  resetPassword,
  confirmResetPassword,
  updatePassword,
  confirmSignUp
} from 'aws-amplify/auth';
import {Hub} from 'aws-amplify/utils';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  /**
   * Changes whenever the current authentication status of the user changes.
   */
  public isAuthenticated = new BehaviorSubject<boolean>(
    false
  );

  private cognitoUser: any;

  /**
   * Set upon successful authentication to the list of permissions the user has. Defaults to a list
   * of no permissions.
   */
  public policies = new ReplaySubject<string[]>(1);

  private _policies: string[];

  /**
   * Determines whether a user has a particular permission. This is vulnerable to a race condition,
   * prefer asynchronously checking permissions as above.
   */
  public hasPolicy = (perm: string) => this._policies?.includes(perm);

  constructor(
    private userService: UserService,
    private orgService: OrganisationService,
    private ngxLogger: NGXLogger,
    private dialog: MatDialog,
    private router: Router) {

    // Either www.barxui.com or barxui.com based on what the browser requested
    const urlOrigin = window.location.origin;

    Hub.listen('auth', data => this.authEvent(data));

    // Listen for successful authentication attempts and load their associated permissions
    this.isAuthenticated.pipe(
      switchMap(authed => {
        return authed ? this.userService.getUserPolicies() : of(new Array<string>());
      })
    ).subscribe(policies => {
      this._policies = policies;
      // this.ngxLogger.debug('Got policies', this._policies);
      return this.policies.next(policies);
    });

    getCurrentUser().then(user => {
      // console.log('got user at startup');
      // console.log(user);
      this.cognitoUser = user;
      this.isAuthenticated.next(true);
    }).catch(err => {
      this.cognitoUser = null;
      this.isAuthenticated.next(false);
      // console.log('No user');
    });
  }


  /**
   * Get a JWT Token for the current session
   */
  getToken() {
    return from(
      new Promise((resolve, reject) => {
        fetchAuthSession().then((session) => {
          if (!session.tokens.idToken) {
            resolve(null);
          } else {
            resolve(session.tokens.idToken.toString());
          }
        })
          .catch(err => {
            return resolve(null)
          });
      })
    );
  }

  async login(email: string, password: string, resumeStateUrl: string = this.router.url): Promise<any> {

    try {
      const { isSignedIn, nextStep } = await signIn({
        username: email,
        password
      });

      merge(this.orgService.resetCache(), this.userService.resetCache()).subscribe(_ => {
      });

      this.cognitoUser = null;
      return isSignedIn;

    } catch (err) {
      throw err;
    }

  }

  async register(
    // name: string,
    email: string,
    password: string,
    // phone: string
    inviteCode: string,
    orgname: string
  ): Promise<any | void> {

    return await signUp({
      username: email,
      password,
      options: {
        userAttributes: {
          "custom:inviteCode": inviteCode,
          "custom:orgname": orgname
        }
      }
    }).then(result => {
      this.cognitoUser = null;
      merge(this.orgService.resetCache(), this.userService.resetCache()).subscribe(_ => {
      });

      return true;

    }).catch(err => {
      throw err;
    });
  }

  async resendConfirmation(email: string): Promise<boolean> {
    try {
      const result = await resendSignUpCode({username: email});
      return true;
    } catch (err) {
      throw err;
    }
  }

  async confirm(
    email: string,
    code: string): Promise<boolean> {

    try {
      await confirmSignUp({username: email, confirmationCode: code});
      return true;
    } catch (err) {
      throw err;
    }
  }

  logout(): void {
    signOut()
      .then(_ => {
        this.cognitoUser = null;

        merge(this.orgService.resetCache(), this.userService.resetCache()).subscribe(_ => {
          this.router.navigateByUrl('/').then(_ =>
            // force a reload solves a couple of issues
            window.location.replace(environment.aws.oauth.redirectSignOut));
        });
      })
      .catch(err => console.log(err));
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<boolean> {

    try {

      await updatePassword({oldPassword, newPassword});
      return true;
    } catch (err) {
      throw err;
    }
  }

  async forgotPassword(email: string): Promise<boolean> {

    try {
      const result = await resetPassword({username: email});
      return true;
    } catch (err) {
      throw err;
    }
  }

  async forgotPasswordUpdate(email: string, code: string, password: string): Promise<boolean> {

    try {
      const result = await confirmResetPassword({username: email, confirmationCode: code, newPassword: password});
      return true;
    } catch (err) {
      throw err;
    }
  }

  private authEvent(data: any): void {
    this.ngxLogger.debug(`AuthEvent: ${data.payload.event}`, data);

    switch (data.payload.event) {
      case 'signedIn':
        this.isAuthenticated.next(true);
        break;
      case 'signedUp':
        break;
      case 'signedOut':
        this.isAuthenticated.next(false);
        break;
      case 'signIn_failure':
        this.isAuthenticated.next(false);
        break;
      case 'tokenRefresh':
        break;
      case 'tokenRefresh_failure':
        this.isAuthenticated.next(false);

        this.dialog.open(SessionExpiredDialogComponent, {
          disableClose: true,
          role: 'alertdialog',
          data: () => {
            this.ngxLogger.debug('Session Expired Logout');
            this.logout();
          }
        });
        break;
      case 'configured':

        // Auth.currentAuthenticatedUser().then(user => {
        //   if (user) {
        //     console.log('got user in event');
        //     console.log(user);
        //     this.user = user;
        //     this.isAuthenticated.next(true);
        //   }
        // })

        break;
    }
  }
}
